import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Accordion, AccordionSummary, ThemeButton } from "../CustomComponents";

import "./index.css";
import { Themes, Sections, Boroughs } from "../../data/config-data";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import MapContentType from "../MapContentType";
import SeeMoreDetails from "../SeeMoreDetails";
import ThemeSectionDetails from "../ThemeSectionDetails";
import { GetContent } from "../../Util";
import { motion, AnimatePresence } from "framer-motion";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

//temp
const MapSectionDetails = (props) => {
  let navigate = useNavigate();
  const [openSeeMore, setOpenSeeMore] = useState(false);
  const [openThemeDetails, setOpenThemeDetails] = useState(false);
  const [contentType, setContentType] = useState(props.contentType);
  const [borough, setBorough] = useState(Boroughs[props.borough]);
  const [boroughContent, setBoroughContent] = useState(props.content);
  const [currentTheme, setCurrentTheme] = useState("");
  const [currentContent, setCurrentContent] = useState([]);
  const [currentSection, setCurrentSection] = useState("");
  const [postcodeGroup, setPoscodeGroup] = useState([]);
  const [selectedPostcode, setSelectedPostcode] = useState("");
  const [contentThemes, setContentThemes] = useState([]);
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);
  const [sectionColor, setSectionColor] = useState(props.settings.color);
  const [currentSectionIcon, setCurrentSectionIcon] = useState(
    props.settings.icon
  );
  const [seeMoreContent, setSeeMoreContent] = useState([]);

  useEffect(() => {
    setupContentGrouping(boroughContent);
    setCurrentSection(props.section);
    //setupseemore
  }, []);

  const UpdateThemes = (cont) => {
    let cat = cont.categories.toString();
    let _themes = [];
    // console.log("cat  ", cat);
    if (cat.indexOf(",") > -1) {
      let split = cat.split(",");
      _themes = [...split];
    } else {
      _themes.push(cat);
    }
    setContentThemes(_themes);
  };

  const setupContentGrouping = (bc) => {
    // let _groups = _.groupBy(bc, "postcode");
    let _groups = _.groupBy(bc, (o) => _.get(o, "postcode", "OTHER"));

    //Order postcode
    const preOrdered = Object.keys(_groups)
      .sort()
      .reduce((obj, key) => {
        if (key) {
          obj[key] = _groups[key];
        } else {
          obj["OTHER"] = _groups[key];
        }
        return obj;
      }, {});

    const ordered = _(preOrdered).toPairs().sortBy(0).fromPairs().value();

    //console.log("postcode groups -> ", ordered);
    let firstPostcode = Object.keys(ordered)[0];
    setPoscodeGroup(ordered);
    setSelectedPostcode(firstPostcode);

    let shuffledContent = _.shuffle(ordered[firstPostcode]);

    setCurrentContent(shuffledContent);
    // console.log("_groups[selectedPostcode] -> ", _groups[firstPostcode]);

    //
    // console.log("themes -> ",);
    CheckSeeAlsoContent(shuffledContent[0]);

    UpdateThemes(shuffledContent[0]);
  };

  const handleSectionChange = (options) => {
    let data = GetContent(options.section);
    let _groups = _.groupBy(data, "borough");
    setBoroughContent(_groups[borough.name.toLowerCase()]);
    setupContentGrouping(_groups[borough.name.toLowerCase()]);
    setCurrentSection(options.section);

    let results = Sections.filter((obj) => {
      return obj.path === options.section;
    });

    setCurrentSectionIcon(results[0].icon);

    setSectionColor(results[0].color);
    //console.log("results -> ", results);
  };

  const sectionHasContent = (section) => {
    let data = GetContent(section);
    let _groups = _.groupBy(data, "borough");
    // console.log("sectionHasContent", borough.name.toLowerCase());
    return Object.keys(_groups).includes(borough.name.toLowerCase());
  };

  const HandleSelectedPostcode = (p) => {
    setSelectedPostcode(p);

    let shuffledContent = _.shuffle(postcodeGroup[p]);
    setCurrentContent(shuffledContent);
    UpdateThemes(shuffledContent[0]);
    CheckSeeAlsoContent(shuffledContent[0]);
  };

  const CheckSeeAlsoContent = (cont) => {
    if (cont.seealso) {
      let cat = cont.seealso.toString();
      let _sa = [];
      if (cat) {
        if (cat.indexOf(",") > -1) {
          let split = cat.split(",");
          _sa = [...split];
        } else {
          _sa.push(cat);
        }
        setSeeMoreContent(_sa);
        //console.log("checking see more .....", _sa);
      }
    } else {
      setSeeMoreContent([]);
    }
  };

  return (
    <motion.div
      className="screen-page"
      initial={{ scale: 0, x: 0, y: 0, opacity: 0 }}
      animate={{ scale: 1, x: 0, y: 0, opacity: 1 }}
      transition={{ duration: 0.5, type: "spring" }}
    >
      {/* See more */}
      <AnimatePresence>
        {openSeeMore && (
          <SeeMoreDetails
            contentKeys={seeMoreContent}
            key={`${selectedPostcode}_${currentSection}`}
            handleSeeMoreState={() => {
              setOpenSeeMore(false);
            }}
          />
        )}
      </AnimatePresence>
      {/* Theme details explorer */}
      {openThemeDetails && (
        <ThemeSectionDetails
          {...props}
          sectionColor={sectionColor}
          theme={currentTheme}
          currentSection={currentSection}
          handleThemeDetails={() => {
            setOpenThemeDetails(false);
          }}
        />
      )}
      <Grid
        container
        className="details-holder"
        style={{ display: openThemeDetails || openSeeMore ? "none" : "flex" }}
      >
        <Grid
          item
          container
          lg={4.5}
          direction="column"
          alignItems="center"
          className="details-filters"
          // justifyContent="center"
        >
          {/* Borough title */}
          <div
            className="borough-holder"
            style={{ backgroundColor: sectionColor }}
          >
            <motion.img
              animate={{ y: [0, 10, 0] }}
              transition={{
                duration: 3,
                // ease: "easeInOut",
                repeat: Infinity,
              }}
              alt=""
              src={"./assets/" + currentSectionIcon + ".png"}
              className="nav-item-icon"
              style={{ top: 0 }}
            />
            <div className="borough-name">{borough.name}</div>
          </div>

          {/* selected postcode */}
          <div
            className="selected-postcode"
            style={{ backgroundColor: sectionColor }}
          >
            {selectedPostcode}
          </div>
          {/* choose postcode */}
          <Accordion elevation={0}>
            <AccordionSummary>
              <h4>Choose a postcode</h4>
            </AccordionSummary>

            <div className="postcode-holder">
              <div className="postcode-items">
                {Object.keys(postcodeGroup).map((v, i) => {
                  let isSelectedPostcode = v == selectedPostcode;
                  return (
                    <PostcodeButton
                      isSelected={isSelectedPostcode}
                      color={sectionColor}
                      key={i}
                      name={v}
                      handlePostcode={() => HandleSelectedPostcode(v)}
                    />
                  );
                })}
              </div>
            </div>
          </Accordion>
          {/* select a theme */}
          <Accordion>
            <AccordionSummary>
              <h4>Related themes</h4>
            </AccordionSummary>

            <div className="themes-holder">
              {Object.keys(Themes).map((v, i) => (
                <ThemeButton
                  key={v}
                  disable={!contentThemes.includes(v)}
                  name={Themes[v].name}
                  handleThemeDetails={() => {
                    setOpenThemeDetails(true);
                    setCurrentTheme(v);
                  }}
                />
              ))}
            </div>
          </Accordion>

          {/* select Category */}
          <Accordion>
            <AccordionSummary>
              <h4>More from this area</h4>
            </AccordionSummary>
            <div className="section-holder">
              {Sections.map(
                // Hack to get first 5
                (v, i) => {
                  if (i < 5) {
                    if (sectionHasContent(v.path)) {
                      let isSelectedPostcode = v.path == currentSection;
                      //console.log(currentSection, v.section);
                      return (
                        <SectionButton
                          key={i}
                          {...v}
                          disable={false}
                          isSelected={isSelectedPostcode}
                          handleSection={(options) => {
                            handleSectionChange(options);
                            setContentType(v.contentType);
                          }}
                        />
                      );
                    } else {
                      return (
                        <SectionButton
                          key={i}
                          {...v}
                          disable={true}
                          handleSection={
                            (options) => {}
                            // handleSectionChange(options)
                          }
                        />
                      );
                    }
                  }
                }
              )}
            </div>
          </Accordion>
        </Grid>
        <Grid
          item
          container
          lg={7.5}
          direction="row"
          alignItems="center"
          className="details-info"
        >
          <MapContentType
            contentType={contentType}
            content={currentContent}
            sectionColor={sectionColor}
            key={`${currentSection}_${selectedPostcode}`}
            themeOpen={openThemeDetails == openSeeMore}
            onUpdate={(idx) => {
              setCurrentSliderIndex(idx);
              UpdateThemes(currentContent[idx]);
              CheckSeeAlsoContent(currentContent[idx]);
              // console.log("index  => ", idx);
              //console.log("current content => ", currentContent[idx]);
            }}
          />
          {seeMoreContent.length > 0 && (
            <div
              className="btn-seemore"
              onClick={() => {
                setOpenSeeMore(true);
              }}
            >
              <KeyboardArrowUpIcon style={{ fontSize: 60 }} /> See also
            </div>
          )}
        </Grid>
        <div className="home-back-navigation">
          <div
            className="btn-details-home btn-nav"
            onClick={() => {
              navigate("/");
            }}
          >
            <span>home</span>
          </div>
          <div className="btn-details-back btn-nav" onClick={props.hideDetails}>
            <span>back</span>
          </div>
        </div>
      </Grid>
    </motion.div>
  );
};

const PostcodeButton = (props) => {
  return (
    <div
      className="postcode-btn"
      style={{ backgroundColor: props.isSelected ? props.color : "#452573" }}
      onClick={() => {
        props.handlePostcode();
      }}
    >
      {props.name}
    </div>
  );
};

const SectionButton = (props) => {
  let navigate = useNavigate();

  return (
    <Grid
      item
      // lg={4}
      sm={5}
      className="section-btn"
      style={{
        opacity: props.disable ? 0.2 : 1,
        backgroundColor: props.color,
        boxShadow: props.isSelected
          ? "0px 5px 0px 0px rgba(0, 0, 0, 0.3) inset"
          : "0px 5px 0px 0px rgba(0, 0, 0, 0.3)",
      }}
      onClick={() => {
        let options = {
          section: props.path,
        };
        props.handleSection(options);
        // console.log(props.name);
        // navigate(`../${props.path}`);
      }}
    >
      <img
        alt=""
        src={"./assets/" + props.icon + ".png"}
        className="section-btn-icon"
      />
      {props.name}
    </Grid>
  );
};

export default MapSectionDetails;

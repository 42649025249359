import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./index.css";
import { Themes, Sections, Boroughs } from "../../data/config-data";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { GetContent } from "../../Util";
import _ from "lodash";
import MapContentType from "../MapContentType";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import { Accordion, AccordionSummary } from "../CustomComponents";

const ThemeSectionDetails = (props) => {
  let navigate = useNavigate();
  const [contentType, setContentType] = useState(props.contentType);
  const [theme, setCurrentTheme] = useState(props.theme);
  const [selectedSection, setSelectedSection] = useState(props.currentSection);
  const [currentThemeContent, setCurrentThemeContent] = useState([]);
  const [currentContent, setCurrentContent] = useState([]);
  const [currentThemeColor, setCurrentThemeColor] = useState(
    props.sectionColor
  );
  const [sectionInfo, setSectionInfo] = useState({});
  const [currentSectionIcon, setCurrentSectionIcon] = useState(
    props.settings.icon
  );
  useEffect(() => {
    console.log("props -> ", props);

    let ct = Sections.filter((obj) => {
      return obj.path === selectedSection;
    })[0];
    setContentType(ct.contentType);
    setCurrentSectionIcon(ct.icon);
    // console.log("props.filteredSections -> ", Sections);
    themeContent(selectedSection);
    //console.log("contentType -> ", props.contentType);
  }, []);

  const getSectionInfo = (_section) => {
    let results = Sections.filter((obj) => {
      return obj.path === _section;
    });
    setCurrentSectionIcon(results[0].icon);
    setCurrentThemeColor(results[0].color);
  };

  const hasThemeContent = (section) => {
    let data = GetContent(section);
    let parsedData = [];

    // console.log("data -> ", section, data);
    data?.forEach((v, i) => {
      let cat = v.categories.toString();
      if (cat.indexOf(",") > -1) {
        let split = cat.split(",");
        split.forEach((vl, idx) => {
          let newItem = { ...v };
          newItem.categories = vl;
          parsedData.push(newItem);
        });
      } else {
        parsedData.push(v);
      }
    });

    //
    // data.map();
    let _groups = _.groupBy(parsedData, "categories");

    return Object.keys(_groups).includes(theme);
  };

  const themeContent = (section) => {
    let data = GetContent(section);
    let parsedData = [];

    data.forEach((v, i) => {
      let cat = v.categories.toString();
      if (cat.indexOf(",") > -1) {
        let split = cat.split(",");
        split.forEach((vl, idx) => {
          let newItem = { ...v };
          newItem.categories = vl;
          parsedData.push(newItem);
        });
      } else {
        parsedData.push(v);
      }
    });

    //
    // data.map();
    let _groups = _.groupBy(parsedData, "categories");

    if (Object.keys(_groups).includes(theme)) {
      setCurrentThemeContent(_groups[theme]);
      setSelectedSection(section);
      setCurrentContent(_groups[theme][0]);
      //console.log("SECTION HAS CONTENT ");
    } else {
      //console.log("SECTION HAS NO CONTENT ");
    }
  };

  return (
    <motion.div
      className="screen-page"
      initial={{ scale: 0, x: 0, y: 0, opacity: 0 }}
      animate={{ scale: 1, x: 0, y: 0, opacity: 1 }}
      transition={{ duration: 0.5, type: "spring" }}
    >
      {/* <div
        className="btn-theme-close"
        onClick={() => {
          props.handleThemeDetails();
        }}
      >
        <CloseIcon style={{ fontSize: 40, marginRight: 10 }} color="inherit" />
        <p>close</p>
      </div> */}
      <Grid container className="theme-details-holder" spacing={2}>
        <Grid
          item
          container
          lg={4.5}
          direction="column"
          alignItems="center"
          className="theme-details-filters"
          // justifyContent="center"
        >
          {/* Borough title */}
          <div
            className="theme-title-holder"
            style={{ backgroundColor: currentThemeColor }}
          >
            <motion.img
              animate={{ y: [0, 10, 0] }}
              transition={{
                duration: 3,
                // ease: "easeInOut",
                repeat: Infinity,
              }}
              alt=""
              src={"./assets/" + currentSectionIcon + ".png"}
              className="nav-item-icon"
              style={{ top: 10 }}
            />
            <div className="theme-title-name">
              <p>{Themes[theme].name}</p>
            </div>
          </div>
          <Grid container justifyContent={"center"}>
            {currentContent?.borough && (
              <div className="theme-location">{currentContent?.borough}</div>
            )}
            {currentContent?.postcode && (
              <div className="theme-location">{currentContent?.postcode}</div>
            )}
          </Grid>

          {/* theme description */}
          <div className="theme-description-holder">
            <Typography> {Themes[theme].description}</Typography>
          </div>
          {/* select Category */}
          <div className="theme-categories">
            <Accordion>
              <AccordionSummary>
                <h4>More from this theme</h4>
              </AccordionSummary>
              <div className="section-holder">
                {Sections.map((v, i) => {
                  if (i < Sections.length && v.name.toLowerCase() !== "quiz") {
                    //console.log("Sections ", v);
                    if (hasThemeContent(v.path)) {
                      let isSelectedPostcode = v.path == selectedSection;

                      return (
                        <SectionButton
                          key={i}
                          details={v}
                          disable={false}
                          {...v}
                          isSelected={isSelectedPostcode}
                          handleSectionChange={() => {
                            themeContent(v.path);
                            //console.log("v.contentType -> ", v.contentType);
                            setContentType(v.contentType);
                            getSectionInfo(v.path);
                          }}
                        />
                      );
                    } else {
                      return (
                        <SectionButton
                          key={i}
                          details={v}
                          disable={true}
                          {...v}
                          handleSectionChange={() => {
                            // themeContent(v.path);
                            // console.log("v.contentType -> ", v.contentType);
                            // setContentType(v.contentType);
                          }}
                        />
                      );
                    }
                  }
                })}
              </div>
            </Accordion>
          </div>
          {/* <div className="btn-navigation-holder">
            <div
              className="btn-details-home btn-nav"
              onClick={() => {
                navigate("/");
              }}
            >
              <span>home</span>
            </div>
            <div
              className="btn-details-back btn-nav"
              onClick={props.handleThemeDetails}
            >
              <span>back</span>
            </div>
          </div> */}
        </Grid>
        <Grid
          item
          container
          lg={7.5}
          direction="row"
          alignItems="center"
          className="theme-details-info"
        >
          <MapContentType
            isTheme={true}
            contentType={contentType}
            content={currentThemeContent}
            sectionColor={currentThemeColor}
            key={`${currentThemeContent.length}_${selectedSection}`}
            onUpdate={(idx) => {
              // console.log(currentThemeContent[idx]);
              setCurrentContent(currentThemeContent[idx]);
            }}
            // asset="https://test-videos.co.uk/vids/jellyfish/mp4/h264/1080/Jellyfish_1080_10s_1MB.mp4"
          />
        </Grid>
        <div className="home-back-navigation">
          <div
            className="btn-details-home btn-nav"
            onClick={() => {
              navigate("/");
            }}
          >
            <span>home</span>
          </div>
          <div
            className="btn-details-back btn-nav"
            onClick={() => {
              props.handleThemeDetails();
            }}
          >
            <span>back</span>
          </div>
        </div>
      </Grid>
    </motion.div>
  );
};

// const PostcodeButton = (props) => {
//   return (
//     <div
//       className="postcode-btn"
//       onClick={() => {
//         console.log(props.name);
//       }}
//     >
//       {props.name}
//     </div>
//   );
// };

// const ThemeButton = (props) => {
//   return (
//     <div
//       className="theme-btn"
//       onClick={() => {
//         console.log(props.name);
//       }}
//     >
//       {props.name}
//     </div>
//   );
// };

const SectionButton = (props) => {
  return (
    <Grid
      className="section-btn"
      sm={5}
      item
      style={{
        opacity: props.disable ? 0.2 : 1,
        backgroundColor: props.details.color,
        boxShadow: props.isSelected
          ? "0px 5px 0px 0px rgba(0, 0, 0, 0.3) inset"
          : "0px 5px 0px 0px rgba(0, 0, 0, 0.3)",
      }}
      onClick={() => {
        props.handleSectionChange();
      }}
    >
      <img
        alt=""
        src={"./assets/" + props.icon + ".png"}
        className="section-btn-icon"
      />
      {props.details.name}
    </Grid>
  );
};

export default ThemeSectionDetails;

import React from "react";
import MapSectionMenu from "../components/MapSectionMenu";
const DirectionTask = () => {
  const settings = {
    title: "Directions Task",
    icon: "02 Directions",
    section: "directions-task",
    contentType: "directions-task",
    mapImage: "Map_Directions",
    color: "#FA9D3B",
  };

  return (
    <>
      <MapSectionMenu {...settings} />
    </>
  );
};

export default DirectionTask;

import React, { useState, useEffect } from "react";
import "./index.css";
import { Box, Grid, Typography } from "@mui/material";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Boroughs, Sections } from "../../data/config-data";
import MapSectionDetails from "../MapSectionDetails";
import { GetContent } from "../../Util";
import _ from "lodash";
import { motion, AnimatePresence } from "framer-motion";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MapSectionMenu = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let navigate = useNavigate();
  let iconImage = "./assets/" + props.icon + ".png";
  // console.log("props", props);

  const [openDetails, setOpenDetails] = useState(false);
  const [boroughContent, setBoroughContent] = useState([]);
  const [groupByBorough, setGroupByBorough] = useState({});
  const [currentBorough, setCurrentBorough] = useState(0);
  const [sectionSettings, setSectionSettings] = useState({ filter: "" });

  useEffect(() => {
    let data = GetContent(props.section);
    let _settings = Sections.filter((v, i) => {
      //console.log(v);
      return v.path == props.section;
    })[0];
    setSectionSettings(_settings);

    let _groups = _.groupBy(data, "borough");
    setGroupByBorough(_groups);
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="screen-page"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {openDetails ? (
          <MapSectionDetails
            borough={currentBorough}
            content={boroughContent}
            contentType={props.contentType}
            section={props.section}
            settings={props}
            hideDetails={() => {
              setOpenDetails(false);
            }}
          />
        ) : (
          <Grid container className="map-overview-holder" spacing={4}>
            <Grid item lg={4} className="map-page-left">
              <div className="map-page-intro">
                <div
                  className="title-holder"
                  style={{
                    backgroundColor: props.color,
                    // boxShadow: "1px 10px 5px rgba(0, 0, 0, 0.75)",
                  }}
                >
                  <motion.img
                    animate={{ y: [0, -5, 0] }}
                    transition={{
                      duration: 3,
                      // ease: "easeInOut",
                      repeat: Infinity,
                    }}
                    alt=""
                    src={iconImage}
                    className="nav-item-icon"
                    style={{ width: 70, height: 70, top: 20 }}
                  />
                  <div className="title-name">{props.title}</div>
                </div>
                <div
                  className="section-description"
                  style={{
                    backgroundColor: props.color,
                    // boxShadow: "1px 10px 5px rgba(0, 0, 0, 0.75)",
                  }}
                >
                  <Typography>{sectionSettings.description}</Typography>
                </div>
                <div className="instructions" onClick={() => {}}>
                  <Typography variant="h5">Choose a borough</Typography>
                  <motion.div
                    animate={{ scale: [1, 0.9, 1] }}
                    transition={{
                      duration: 3,
                      // ease: "easeInOut",
                      repeat: Infinity,
                    }}
                    className="instructions-icon"
                    style={{ backgroundColor: props.color }}
                  ></motion.div>
                </div>
              </div>
            </Grid>
            <Grid item container lg={8} className="map-page-right">
              <div className="map-section-holder">
                <div
                  className="map-holder"
                  style={{
                    backgroundImage: `url(./assets/${props.mapImage}.png)`,
                  }}
                >
                  {isSmallScreen && (
                    <Grid container className="map-holder-items-small">
                      {Boroughs.map((v, i) => {
                        let _avail = Object.keys(groupByBorough);
                        let hasContent = _avail.includes(v.name.toLowerCase());

                        if (hasContent) {
                          // console.log(v);
                          return (
                            <Grid
                              item
                              key={i}
                              xs={5.5}
                              // style={{}}
                              className="map-nav-item"
                              // style={{ left: v.x, top: v.y }}
                              // style={{ left: `${v.x}%`, top: `${v.y}%` }}
                              onClick={() => {
                                let bContent =
                                  groupByBorough[v.name.toLowerCase()];
                                // console.log("bContent -> ", bContent);

                                // console.log(i);
                                setCurrentBorough(i);
                                setBoroughContent(bContent);
                                setOpenDetails(true);
                                // navigate("/map-details");
                              }}
                            >
                              <div className="selectable-icon"></div>
                              <div className="map-nav-item-name"> {v.name}</div>
                            </Grid>
                          );
                        }
                        return (
                          <Grid
                            item
                            xs={5.5}
                            key={i}
                            className="map-nav-item-noactive"
                            // style={{ left: `${v.x}%`, top: `${v.y}%` }}
                          >
                            {v.name}
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                  {!isSmallScreen &&
                    Boroughs.map((v, i) => {
                      let _avail = Object.keys(groupByBorough);
                      let hasContent = _avail.includes(v.name.toLowerCase());

                      if (hasContent) {
                        // console.log(v);
                        return (
                          <div
                            key={i}
                            className="map-nav-item"
                            // style={{ left: v.x, top: v.y }}
                            style={{ left: `${v.x}%`, top: `${v.y}%` }}
                            onClick={() => {
                              let bContent =
                                groupByBorough[v.name.toLowerCase()];
                              // console.log("bContent -> ", bContent);

                              // console.log(i);
                              setCurrentBorough(i);
                              setBoroughContent(bContent);
                              setOpenDetails(true);
                              // navigate("/map-details");
                            }}
                          >
                            <motion.div
                              animate={{ scale: [1, 0.7, 1], y: [0, -10, 0] }}
                              transition={{
                                duration: 5,
                                // ease: "easeInOut",
                                delay: 0.2 * i,
                                repeat: Infinity,
                              }}
                              className="selectable-icon"
                            ></motion.div>
                            <div className="map-nav-item-name"> {v.name}</div>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={i}
                          className="map-nav-item"
                          style={{ left: `${v.x}%`, top: `${v.y}%` }}
                        >
                          {v.name}
                        </div>
                      );
                    })}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default MapSectionMenu;

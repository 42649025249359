import logo from "./logo.svg";
import "./App.css";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu"; //screens
import InfoIcon from "@mui/icons-material/Info";
import Home from "./screens/Home";
import Perception from "./screens/Perception";
import DirectionTask from "./screens/DirectionTask";
import Poetry from "./screens/Poetry";
import AccentVan from "./screens/AccentVan";
import Archive from "./screens/Archive";
import Quiz from "./screens/Quiz";
import Podcast from "./screens/Podcast";
import Pronunciation from "./screens/Pronunciation";
import MapSectionDetails from "./components/MapSectionDetails";
import { Box, Grid, Container, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useIdleTimer } from "react-idle-timer";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Sections } from "./data/config-data";

function App() {
  let navigate = useNavigate();
  const location = useLocation();
  const [showInfo, setShowInfo] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    navigate("/");
  };

  const idleTimer = useIdleTimer({ onIdle, timeout: 1000 * 60 * 20 });

  return (
    <Container className="App" maxWidth="xl" disableGutters>
      <div className="main-header">
        <div
          className="home-logo"
          onClick={() => {
            navigate("/");
            setShowInfo(false);
          }}
        ></div>

        <div className="right-nav">
          <div
            className="btn-nav-menu"
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon style={{ fontSize: 30 }} color="inherit" />
          </div>
          <div
            className="btn-nav-menu"
            onClick={() => {
              // setOpenDrawer(true);
              setShowInfo((pre) => !pre);
            }}
          >
            <InfoIcon style={{ fontSize: 30 }} color="inherit" />
          </div>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        className="drawer-holder"
      >
        <Box sx={{ width: 300 }} role="presentation">
          <List>
            <ListItem
              key="home"
              component={Link}
              to={"/"}
              onClick={() => {
                setOpenDrawer(false);
              }}
              className="drawer-item"
            >
              <ListItemButton>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            <Divider />

            {Sections.map((section, index) => (
              <ListItem
                key={index}
                component={Link}
                to={section.path}
                onClick={() => {
                  setOpenDrawer(false);
                  setShowInfo(false);
                }}
                className="drawer-item"
              >
                <ListItemButton>
                  <ListItemText primary={section.name} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider />
            <ListItem
              key="about"
              onClick={() => {
                setOpenDrawer(false);
                setShowInfo(true);
              }}
              className="drawer-item"
            >
              <ListItemButton>
                <ListItemText primary="About this project" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="podcast" element={<Podcast />} />
          <Route path="directions-task" element={<DirectionTask />} />
          <Route path="poetry" element={<Poetry />} />
          <Route path="accent-van" element={<AccentVan />} />
          <Route path="archive" element={<Archive />} />
          <Route path="quiz" element={<Quiz />} />
          <Route path="perception" element={<Perception />} />
          <Route path="pronunciation" element={<Pronunciation />} />
          {/* <Route path="map-details" element={<MapSectionDetails />} /> */}
        </Routes>
      </AnimatePresence>

      <AnimatePresence>
        {showInfo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="credits"
            onClick={() => {
              setShowInfo((pre) => !pre);
            }}
          >
            <div className="btn-close-credits">
              <CloseIcon
                style={{ fontSize: 20, marginRight: 10 }}
                color="inherit"
              />
              <p>close</p>
            </div>

            <div className="info-copy">
              <div className="selection-instruction">
                <Typography className="intro-bold" variant="h6">
                  Manchester Voices is a research project based at Manchester
                  Metropolitan University. It has been generously funded by the
                  Arts and Humanities Research Council through project grant
                  AH/S006125/1.
                </Typography>

                <Typography className="intro-try" style={{ padding: 20 }}>
                  The research was carried out by Dr Erin Carrie, Dr Holly Dann,
                  Dr Rob Drummond, Dr Sadie Ryan, and Dr Sarah Tasker. The
                  project was co-ordinated by Rob Drummond.
                </Typography>
              </div>

              <Typography
                className="credits-text"
                style={{ fontWeight: "bold" }}
              >
                The project team would like to thank the following people:
              </Typography>
              <Typography className="credits-text">
                The team of Manchester Met student research assistants and
                creatives: Brianna Ataman, William Boyes, Nick Brennand, Birce
                Cennetoglu, Billie Christiansen, Chris Connor, Jay Curley, Polly
                Hodkinson, Zarah Matloob, Sophie Mayo, Labeeba Miah, Monifa
                Phillips, Connor O’Mara, Holly Rochfort, Steph Shuttleworth,
                Aisha Sufi, Jack Taylor, Megan White.
              </Typography>
              <Typography className="credits-text">
                The staff at Manchester Central Library and the Archives+ team,
                in particular Philip Cooke and Dave Govier.
              </Typography>
              <Typography className="credits-text">
                The design and development team at Figment productions, in
                particular Larry Andrews and Courage Semawu.
              </Typography>
              <Typography className="credits-text">
                All the staff and volunteers at the various locations and events
                which hosted The Accent Van in the summer and autumn of 2021.{" "}
              </Typography>
              <Typography className="credits-text">
                Shamshad Khan, Becky Swain and Dr Martin Kratz for organising
                and running the poetry workshops.{" "}
              </Typography>
              <Typography className="credits-text">
                Dr Chris Montgomery from The University of Sheffield for advice
                on the perception maps.
              </Typography>
              <Typography className="credits-text">
                Hazel Dann for illustrating the Directions Task.
              </Typography>
              <Typography className="credits-text">
                The Design Studio at Manchester Met for designing the branding
                for The Accent Van and the display boards.
              </Typography>
              <Typography className="credits-text">
                All the schools and students who took part in our ‘Talking about
                Voices’ podcast course and competition.
              </Typography>
              <Typography
                className="credits-text"
                style={{ fontWeight: "bold" }}
              >
                And finally, the 1200 people who we estimate took part in the
                project. Thank you for taking the time to share your thoughts,
                insights and voices. The project wouldn’t exist without you.
              </Typography>
              <Typography className="credits-text">
                If you would like any further information about the project,
                please email Manchester.Voices@mmu.ac.uk. You can also visit the
                website: manchestervoices.org, or follow us on Twitter:
                @VoicesMcr, Instagram: @manchestervoices, or Facebook:
                @manchestervoices.
              </Typography>
              <Typography className="credits-text">
                All maps used in this installation were created using data
                derived from the following sources:
              </Typography>
              <Typography className="credits-text">
                www.opendoorlogistics.com
              </Typography>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Typography>
                    Contains National Statistics data © Crown copyright and
                    database right 2022
                  </Typography>
                  <Typography>
                    Contains National Statistics data © Crown copyright and
                    database right 2015
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography>
                    Contains Ordnance Survey data © Crown copyright and database
                    right 2022
                  </Typography>
                  <Typography>
                    Contains Royal Mail data © Royal Mail copyright and database
                    right 2015
                  </Typography>
                </Grid>
              </Grid>
              <Grid></Grid>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="main-footer">
        <Typography>
          &copy;{new Date().getFullYear()} Manchester Voices
        </Typography>
      </div>
    </Container>
  );
}

export default App;

import React from "react";
import MapSectionMenu from "../components/MapSectionMenu";

const Poetry = () => {
  const settings = {
    title: "Poetry",
    icon: "03 Poetry",
    section: "poetry",
    contentType: "poetry",
    mapImage: "Map_Poetry",
    color: "#FF6A84",
  };

  return <MapSectionMenu {...settings} />;
};

export default Poetry;

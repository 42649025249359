import React, { useState, useEffect, useRef } from "react";
import "./Quiz.css";
import { Box, Grid, Typography } from "@mui/material";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { PerceptionCategories } from "../data/config-data";
import MapContentType from "../components/MapContentType";
import { Carousel } from "react-responsive-carousel";
import { GetContent } from "../Util";
import _ from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import ReactPlayer from "react-player";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Quiz = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    let content = GetContent("quiz");
    setData(content);
    setCurrentData(content[0]);
  }, []);

  const handleStep = (num, idx) => {
    setStep(num);
    //console.log(data[idx]);
    setSelectedIndex(idx);
    setCurrentData(data[idx]);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="screen-page section-content-info"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {step == 0 && <QuizIntro quizData={data} handleStep={handleStep} />}
        {step == 1 && (
          <QuizInfo
            quizData={data}
            handleStep={handleStep}
            selectedIndex={selectedIndex}
            currentData={currentData}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};
const QuizIntro = (props) => {
  let navigate = useNavigate();
  // console.log("quizData -> ", props.quizData);
  return (
    <Grid container className="quiz-intro">
      <Grid container item lg={5}>
        <div className="quiz-left">
          <div className="title-holder" style={{ backgroundColor: "#7030A0" }}>
            <motion.img
              animate={{ y: [0, 10, 0] }}
              transition={{
                duration: 3,
                // ease: "easeInOut",
                repeat: Infinity,
              }}
              alt=""
              src={"./assets/06 Quiz.png"}
              className="nav-item-icon"
              style={{ top: 0 }}
            />
            <div className="title-name">Quiz</div>
          </div>
          <div className="selection-instruction">
            <Typography className="intro-bold">
              We ran an Accent Attitudes Quiz, where people listened to voices
              of people from Greater Manchester, described them on different
              scales, and tried to guess where they’re from.
            </Typography>
            <Typography className="intro-try">
              Try the quiz yourself and see how your attitudes compare to our
              results!
            </Typography>

            <Typography className="intro-select">
              Select below from the top 4 most recognised accents and dialects
              in Greater Manchester to hear the voices.
            </Typography>
          </div>

          {/* <div
          className="btn-overview-home"
          onClick={() => {
            navigate("/");
          }}
        >
          <span>home</span>
        </div> */}
        </div>
      </Grid>

      <Grid container item lg={7}>
        <div className="quiz-right">
          <div
            className="instructions"
            style={{ bottom: 200 }}
            onClick={() => {}}
          >
            <h2>Choose a voice</h2>
            <motion.div
              animate={{ scale: [1, 0.9, 1] }}
              transition={{
                duration: 3,
                // ease: "easeInOut",
                repeat: Infinity,
              }}
              className="instructions-icon"
              style={{ backgroundColor: "#7030A0" }}
              // style={{
              //   filter: sectionSettings.filter,
              // }}
            ></motion.div>
          </div>
          <Grid container className="selection-btns">
            {props.quizData &&
              props.quizData.map((v, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    key={i}
                    className="btn-quiz"
                    onClick={() => {
                      props.handleStep(1, i);
                      // console.log("quiz ->", props.quizData[i]);
                    }}
                  >
                    Voice {i + 1}
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
const QuizInfo = (props) => {
  let navigate = useNavigate();
  // console.log("quizData -> ", props.quizData);

  const [answerStage, setAnswerStage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentData, setCurrentData] = useState(props.currentData);
  // const handleBtnResponse = (v) => {
  //   setAnswerStage(v);
  // };
  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef = useRef();

  return (
    <Grid container className="quiz-info" spacing={4}>
      <Grid item container lg={5}>
        <div className="quiz-left">
          <div className="title-holder" style={{ backgroundColor: "#7030A0" }}>
            <motion.img
              animate={{ y: [0, 10, 0] }}
              transition={{
                duration: 3,
                // ease: "easeInOut",
                repeat: Infinity,
              }}
              alt=""
              src={"./assets/06 Quiz.png"}
              className="nav-item-icon"
              style={{ top: 30 }}
            />
            <div className="title-name">Quiz</div>
          </div>
          <div className="selection-instruction">
            <Typography className="intro-bold">
              We ran an Accent Attitudes Quiz, where people listened to voices
              of people from Greater Manchester, described them on different
              scales, and tried to guess where they’re from.
            </Typography>
            <Typography className="intro-try">
              Try the quiz yourself and see how your attitudes compare to our
              results!
            </Typography>

            <Typography className="intro-select">
              Select below from the top 4 most recognised accents and dialects
              in Greater Manchester to hear the voices.
            </Typography>
          </div>
          <Grid container className="selection-btns">
            {props.quizData &&
              props.quizData.map((v, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    key={i}
                    style={{
                      backgroundColor:
                        props.selectedIndex == i ? "#c02aa5" : "",
                    }}
                    className="btn-quiz"
                    onClick={() => {
                      //console.log("quiz ->", answerStage);
                      props.handleStep(1, i);
                      setAnswerStage(1);
                      setCurrentIndex(i);
                    }}
                  >
                    Voice {i + 1}
                  </Grid>
                );
              })}
          </Grid>
          {/* <div
            className="btn-overview-home"
            onClick={() => {
              navigate("/");
            }}
          >
            <span>home</span>
          </div> */}
        </div>
      </Grid>
      <Grid item container lg={7}>
        <div className="quiz-right" key={currentIndex}>
          {answerStage < 5 ? (
            <div className="test-holder">
              <div className="test-instructions">
                <ReactPlayer
                  playsinline={true}
                  url={`/content/${props.currentData.audio}`}
                  ref={playerRef}
                  playing={isPlaying}
                  key={props.id}
                  controls
                  height={50}
                  width="100%"
                  // className="video-player"
                  onEnded={() => {
                    setIsPlaying(false);
                  }}
                />
                <div
                  className="test-audio-holder"
                  onClick={() => {
                    playerRef.current.seekTo(0);
                    setIsPlaying(true);
                  }}
                >
                  <div className="test-audio"></div>
                  <div className="test-audio-text">play audio</div>
                </div>

                <Typography variant="h3">Voice {currentIndex + 1}</Typography>
                <Typography>What is your opinion of this voice?</Typography>
              </div>
              <div className="answer-holder">
                {[0, 1, 2, 3].map((v, i) => {
                  return (
                    <CustomRadioButton
                      answerStage={answerStage}
                      key={i}
                      data={props.currentData}
                      index={i}
                      btnResponse={() => {
                        let stage = i + 2;
                        setAnswerStage(stage);

                        // console.log("stage -> ", stage);
                      }}
                    />
                  );
                })}
              </div>
              <p>Select an option per column</p>
            </div>
          ) : (
            <div className="test-holder">
              <div className="test-instructions">
                <h2>{props.currentData?.title}</h2>
                <p>{props.currentData?.description}</p>
              </div>

              {props.currentData && (
                <div
                  className="result-holder"
                  style={{
                    backgroundImage: `url("/content/${props.currentData?.main_image}")`,
                  }}
                ></div>
              )}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const CustomRadioButton = (props) => {
  const [isSelected, setIsSelected] = useState(-1);
  // console.log("answerStage ", props.answerStage, " index => ", props.index);
  const colors = ["#46039f", "#6f2f9f", "#c029a5", "#e66c5c"];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let ans_1 = props.data[`answer_${props.index + 1}.0`];
  let ans_2 = props.data[`answer_${props.index + 1}.1`];
  const handleSelected = (val) => {
    if (props.index > props.answerStage - 1 || isSelected != -1) return;
    setIsSelected(val);
    props.btnResponse();
  };

  const handleDisplay = () => {
    if (
      (props.index > props.answerStage - 1 ||
        props.index + 1 < props.answerStage) &&
      isSmallScreen
    ) {
      return "none";
    }
    return "flex";
  };
  return (
    <div
      className="custom-radio-btn-holder"
      style={{
        opacity: props.index < props.answerStage ? 1 : 0.1,
        display: handleDisplay(),
      }}
    >
      <div
        className="custom-radio-btn"
        style={{
          backgroundColor:
            isSelected == -1
              ? colors[props.index]
              : isSelected == 0
              ? colors[props.index]
              : "#cecece",
        }}
        onClick={() => {
          handleSelected(0);
        }}
      >
        {ans_1}
      </div>
      <div
        className="custom-radio-btn"
        style={{
          backgroundColor:
            isSelected == -1
              ? colors[props.index]
              : isSelected == 1
              ? colors[props.index]
              : "#cecece",
        }}
        onClick={() => {
          handleSelected(1);
        }}
      >
        {ans_2}
      </div>
    </div>
  );
};

export default Quiz;

const Boroughs = [
  {
    name: "Bolton",
    x: 15,
    y: 19,
  },
  {
    name: "Bury",
    x: 35,
    y: 17,
  },
  {
    name: "Manchester",
    x: 48,
    y: 48,
  },
  {
    name: "Oldham",
    x: 68,
    y: 23,
  },
  {
    name: "Rochdale",
    x: 54,
    y: 10,
  },
  {
    name: "Salford",
    x: 31,
    y: 37,
  },
  {
    name: "Stockport",
    x: 58,
    y: 65,
  },
  {
    name: "Tameside",
    x: 66,
    y: 45,
  },
  {
    name: "Trafford",
    x: 30,
    y: 61,
  },
  {
    name: "Wigan",
    x: 0,
    y: 30,
  },
];

const Themes = {
  0: {
    name: "Accent and Dialect features",
    description:
      "Across Greater Manchester, the accents and dialects you can hear differ in both subtle and very noticeable ways. Explore this theme to learn about and listen to some of the different pronunciations and vocabulary you can hear across the region. \n \n You can listen to participants from our Accent Van share some of the local dialect words and pronunciations that they use, look at Pronunciation and Perception maps, or read some dialect poetry. For a more in depth look at how pronunciations vary across Greater Manchester, you can listen to the data from our Directions Task, and read about the different pronunciations and where they are used. We have described lots of the pronunciations in both the standard alphabet, and also the International Phonetic Alphabet, which appears in square brackets [ ].",
  },
  1: {
    name: "Change",
    description:
      "Alongside our local communities and places, language is constantly changing. Explore this theme to find out about changes in the places, accents and dialects of Greater Manchester. \n \n  You can hear speakers from our Accent Van reflect on changes they have noticed in Greater Manchester over the years, or listen to a poem about some of these changes. Listen to voices from the past in the Archive and hear some of the changes in the accents of Greater Manchester for yourself!",
  },
  2: {
    name: "Ethnicity",
    description:
      "Greater Manchester has long been home to a very diverse and multicultural population. Explore this theme to understand more about how ethnicity and language interact. \n \n Listen to voices from the present day in our Accent Van and Podcast data, or voices from the past in the Archive section. You can listen to people from a range of backgrounds describe how their ethnicity, nationality, or religion has shaped their lives and the language they use. Unfortunately, some people also shared negative experiences of being stereotyped as speaking a certain way because of their perceived ethnicity, or even discriminated against because of the way they speak.",
  },
  3: {
    name: "Local identity",
    description:
      "There is a strong link between people’s identity and the language they use. Explore this theme to find out more about the relationship between place, language and identity within Greater Manchester. \n \n  We found that speakers from our Accent Van often had a sense of pride in their accent and where they were from. Some people identified strongly as Mancunian, or with the wider region of Greater Manchester. However, many people from the boroughs outside central Manchester expressed more affinity with former historic counties, such as Lancashire, or with a specific town. You can look at our Perception maps to see the various accent/dialect regions that people identified within Greater Manchester.",
  },
  4: {
    name: "Miscellaneous",
    description:
      "Here you will find a selection of clips, maps and other materials that did not fit into one of the other eight themes, but are by no means any less interesting.",
  },
  5: {
    name: "Perceptions of speech",
    description:
      "We often make judgements, both positive and negative, about the way we and other people speak. Explore this theme to learn more about how the speech of Greater Manchester is perceived. \n \n You can look at our Perception maps to discover differences between how the speech in different areas of Greater Manchester is viewed by locals. You can also listen to speakers from our Accent Van and Podcasts reflect on how their speech has been perceived by other people and share some of their own perceptions about different accents.",
  },
  6: {
    name: "Prejudice",
    description:
      "When perceptions of accents and dialects are negative, this can sometimes lead to prejudice. When we look at descriptions of the accents and dialects within Greater Manchester, we can see that accents from certain areas are often described in quite negative terms. Explore this theme to hear some our speakers recount their own personal experiences of language-based prejudice and discrimination in our Accent Van and Podcast data.",
  },
  7: {
    name: "Social class",
    description:
      "Linguists have consistently found a link between social class and language. Explore this theme to learn more about how social class and language interact in Greater Manchester. \n \n In our Perception data, there was a tendency for the accents of Greater Manchester to be labelled in relation to perceived social status. In our Accent Van, people also often described a geographical class divide between the north and south of Greater Manchester, although some people felt that this divide had been exaggerated. Many of our speakers also expressed that they felt they were automatically judged as working class because of their Northern accent.",
  },
  8: {
    name: "Styles of speech",
    description:
      "People rarely speak in the same way all the time. Instead, we tend to adjust our speech depending on the situation. For example, we might use fewer local dialect words with people who are from a different area to us, or we might try to sound more formal in a work situation. We might even use more local accent features so as to sound more relatable or friendly. Explore this theme to hear people from our Accent Van reflect on the way that their speech can vary according to the situation, and how some people seem to adjust their speech in an ‘unnatural’ or ‘inauthentic’ way.",
  },
};
const PerceptionCategories = {
  socialAttractiveness: { name: "Social Attractiveness", color: "#0b0785" },
  accentedness: { name: "Accentedness", color: "#46039f" },
  authenticity: { name: "Authenticity", color: "#6f2f9f" },
  miscellaneous: { name: "Miscellaneous", color: "#c029a5" },
  regions: { name: "Regions", color: "#e66c5c" },
  Status: { name: "Status", color: "#f99c3a" },
  accentOrDialet: { name: "Accent or Dialect Features", color: "#fec000" },
};
const Syllable = {
  vowels: "Vowels",
  consonants: "Consonants",
};

const Sections = [
  {
    name: "Accent Van",
    path: "accent-van",
    icon: "04 Accent van",
    contentType: "accent-van",
    description:
      "In 2021 we sent our Accent Van on tour around Greater Manchester. The Accent Van is a mobile recording studio, offering a quiet place for people to reflect on where they live, the way they speak, and how it relates to who they are. It was also possible to take part online in the Virtual Accent Van. In total, 421 people from across Greater Manchester took part, with 200 in The Accent Van and 221 in the Virtual Accent Van.",
    color: "#C12AA6",

    filter:
      "invert(22%) sepia(71%) saturate(3025%) hue-rotate(293deg) brightness(95%) contrast(89%)",
  },
  {
    name: "Directions Task",
    path: "directions-task",
    icon: "02 Directions",
    contentType: "directions-task",
    description:
      "We asked people from all over Greater Manchester to record themselves guiding someone along a route in a map. We deliberately chose particular landmarks on the map to encourage people to say words which we thought might differ in pronunciation across the region. Listening to these example recordings gives a good sense of how accents vary across the ten boroughs of Greater Manchester.",
    color: "#FA9D3B",
    filter:
      "invert(81%) sepia(35%) saturate(3543%) hue-rotate(330deg) brightness(99%) contrast(97%)",
  },
  {
    name: "Podcast",
    path: "podcast",
    icon: "01 Podcast",
    contentType: "podcast",
    description:
      "In 2021 and 2022, we ran a podcasting course and competition for schools called Talking About Voices. Students recorded and edited their own podcast episodes about their experiences of the relationship between language and identity. We hope to run the course again in the future",
    color: "#A6DE34",
    filter:
      "invert(99%) sepia(94%) saturate(1464%) hue-rotate(20deg) brightness(97%) contrast(78%)",
  },
  {
    name: "Poetry",
    path: "poetry",
    icon: "03 Poetry",
    contentType: "poetry",
    description:
      "With the help of staff from the Manchester Poetry Library, we organised poetry workshops in various locations across the region. In these workshops, participants were encouraged to explore their own experiences of the relationship between language, place and identity by discussing and creating poems. Some of these outputs were written down, and some were recorded. We plan to carry out more workshops and add more poems in the future",
    color: "#FF6A84",
    filter:
      "invert(56%) sepia(23%) saturate(1622%) hue-rotate(303deg) brightness(102%) contrast(101%)",
  },

  {
    name: "Archive",
    path: "archive",
    icon: "05 Archive",
    contentType: "archive",
    description:
      "With the help of staff from Archives+ at Manchester Central Library, we accessed historical recordings from the region that have been digitised as part of the British Library’s Unlocking Our Sound Heritage project. We analysed some of these recordings and compared the accents and dialects to those of the present day.",
    color: "#27838E",
    filter:
      "invert(44%) sepia(58%) saturate(515%) hue-rotate(138deg) brightness(88%) contrast(88%)",
  },
  {
    name: "Quiz",
    path: "quiz",
    icon: "06 Quiz",
    contentType: "map-audio",
    description:
      "We ran an Accent Attitudes Quiz, where people listened to voices of people from Greater Manchester, described them on different scales, and tried to guess where they’re from. Try the quiz yourself and see how your attitudes compare to our results!",
    color: "#7030A0",
    filter:
      "invert(22%) sepia(17%) saturate(7497%) hue-rotate(257deg) brightness(94%) contrast(93%)",
  },
  {
    name: "Perception",
    path: "perception",
    icon: "07 Perception",
    contentType: "perception",
    description:
      "We gave 349 people who live in Greater Manchester a map of the region. We asked them to draw shapes around areas where they thought certain accents or dialects were spoken, before describing and labelling them. They gave the area a name, described what they thought of the accent or dialect, and gave examples of its pronunciations, words or grammar. We combined all these responses together to create composite maps of the different dialect regions and themes that emerged from the data.",
    color: "#2BAD80",
    filter:
      "invert(53%) sepia(100%) saturate(302%) hue-rotate(108deg) brightness(87%) contrast(90%)",
  },
  {
    name: "Pronunciation",
    path: "pronunciation",
    icon: "08 Pronounciation",
    contentType: "pronunciation",
    description:
      "In order to find out how accents differ across the region, we analysed recordings of 130 people who have lived in one borough of Greater Manchester for most of their lives, all completing the same directions task. This involved describing a route on a map around a series of landmarks, chosen to represent words that we thought would differ in pronunciation across the region. We then analysed how they pronounced different vowels and consonants in these words. Finally, we created maps and audio examples showing how these pronunciations differ between the 10 boroughs of Greater Manchester.",
    color: "#6DCD5A",
    filter:
      "invert(73%) sepia(33%) saturate(695%) hue-rotate(63deg) brightness(91%) contrast(91%)",
  },
];
export { Boroughs, Themes, Sections, PerceptionCategories, Syllable };

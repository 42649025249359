import React, { useState, useEffect } from "react";
import "./index.css";
import MapContentType from "../MapContentType";
import { Box, Grid } from "@mui/material";
import { GetAllContent } from "../../Util";
import { Themes, Sections, Boroughs } from "../../data/config-data";

import { motion, AnimatePresence } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
const SeeMoreDetails = (props) => {
  const [content, setCotent] = useState([]);
  const [contentType, setContentType] = useState("");
  const [contentTypeIcon, setContentTypeIcon] = useState("");
  useEffect(() => {
    FindContent();
  }, []);

  const GetSectionInfo = (_contType) => {
    let ct = Sections.filter((obj) => {
      return obj.path === _contType;
    })[0];
    //console.log(ct.icon);
    setContentTypeIcon(ct.icon);
  };
  const FindContent = () => {
    let _keys = props.contentKeys;
    let poll = GetAllContent();
    let _mergedContent = [];

    Object.keys(poll).map((v, i) => {
      let cat = poll[v];

      _keys.forEach((id) => {
        let item = _.find(cat, { id: id });
        if (item) {
          let _clone = { ...item };
          _clone.contentType = v;
          _mergedContent.push(_clone);
        }
      });
    });
    if (_mergedContent.length > 0) {
      setCotent(_mergedContent);
      setContentType(_mergedContent[0].contentType);
      GetSectionInfo(_mergedContent[0].contentType);
      //console.log("found i_mergedContenttems -> ", _mergedContent);
    }
  };

  return (
    <motion.div
      // container
      className="seemore-holder"
      // component={motion.div}
      initial={{ scale: 0, x: 0, y: 0, opacity: 0 }}
      animate={{ scale: 1, x: 0, y: 0, opacity: 1 }}
      transition={{ duration: 0.5, type: "spring" }}
    >
      {/* <div className="seemore-icon">
        <img
          animate={{ y: [0, 10, 0] }}
          transition={{
            duration: 3,
            repeat: Infinity,
          }}
          alt=""
          src={"./assets/" + contentTypeIcon + ".png"}
          style={{
            width: 100,
          }}
        />
      </div> */}
      <div
        className="btn-seemore-close"
        onClick={() => {
          props.handleSeeMoreState();
        }}
      >
        <CloseIcon style={{ fontSize: 30, marginRight: 10 }} color="inherit" />
        <p>close</p>
      </div>
      <Grid container item lg={7} className="seemore-details">
        {content.length > 0 && (
          <MapContentType
            isWhiteFont={true}
            key={content[0].id}
            isSeeAlso={true}
            contentType={contentType}
            content={content}
            onUpdate={(idx) => {
              //console.log("......seemore...", idx);
              setContentType(content[idx].contentType);
              GetSectionInfo(content[idx].contentType);
            }}
          />
        )}
      </Grid>
    </motion.div>
  );
};

export default SeeMoreDetails;

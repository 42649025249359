import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";

const APIContext = createContext();

export function APIContextProvider({ children }) {
  const [contentData, setContentData] = useState([]);
  useEffect(() => {
    function fetchData() {
      const { data } = {}; //await axios.get("/content/content.json");
      //console.log("fetching...data...", data);
      setContentData(data);
    }
    fetchData();
  }, []);
  return (
    <APIContext.Provider
      value={{
        contentData,
      }}
    >
      {children}
    </APIContext.Provider>
  );
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

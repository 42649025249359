import React from "react";
import MapSectionMenu from "../components/MapSectionMenu";

const Podcast = () => {
  const settings = {
    title: "Podcast",
    icon: "01 Podcast",
    section: "podcast",
    contentType: "podcast",
    mapImage: "Map_Podcast",
    color: "#A6DE34",
  };

  return (
    <>
      <MapSectionMenu {...settings} />
    </>
  );
};

export default Podcast;

import React from "react";
import MapSectionMenu from "../components/MapSectionMenu";

const Archive = () => {
  const settings = {
    title: "Archive",
    icon: "05 Archive",
    section: "archive",
    contentType: "archive",
    mapImage: "Map_Archive",
    color: "#27838E",
  };

  return (
    <>
      <MapSectionMenu {...settings} />
    </>
  );
};

export default Archive;

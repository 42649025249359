import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import "./index.css";
import { Scrollbar } from "react-scrollbars-custom";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import { Box, Grid, Container, Typography } from "@mui/material";

import _ from "lodash";
const MapContentType = (props) => {
  // test info
  const [asset, setAsset] = useState(props.asset);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [content, setContent] = useState(props.content || [1, 2, 1]);
  const isTheme = props.isTheme || props.isSeeAlso || false;
  const isWhiteFont = props.isWhiteFont || false;
  const isThemeOpen = props.themeOpen || false;

  return (
    <>
      <MapContentDescription
        isTheme={isTheme}
        isWhiteFont={isWhiteFont}
        currentContent={content[currentIndex]}
        contentType={props.contentType}
      />
      <div
        className="media-holder"
        style={{ backgroundColor: props.sectionColor }}
      >
        <div className="current-media-asset">
          {content[currentIndex] && (
            <ContentAsset
              key={`${content[currentIndex].id}_${isThemeOpen}`}
              asset={content[currentIndex % content.length]}
              contentType={props.contentType}
              shouldPlay={false}
              sectionColor={props.sectionColor}
            />
          )}
        </div>
        {/* <div className="media-assets">
          <Carousel
            key={props.selectedPostcode || 1}
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            selectedItem={currentIndex}
            onChange={(index) => {
              if (currentIndex !== index) {
                console.log(currentIndex, index);
                setCurrentIndex(index);
              } else {
                if (props.onUpdate) {
                  props.onUpdate(currentIndex);
                }
              }
            }}
            style={{ height: "100%" }}
          >
            {content.map((v, i) => (
              <div className="citem" key={i}></div>
            ))}
          </Carousel>
        </div> */}
      </div>
      <Grid container className="slider-navigation">
        <Grid item xs={12} sm={4} className="slider-navigation-btn">
          <div
            className="btn-carousel-navigation"
            style={{
              borderColor: props.sectionColor,
              opacity: currentIndex == 0 ? 0.2 : 1,
            }}
            onClick={() => {
              if (currentIndex == 0) return;

              let _idx = currentIndex - (1 % content.length);
              setCurrentIndex(_idx);
              if (props.onUpdate) {
                props.onUpdate(_idx);
              }

              // console.log("current content index ->", _idx);
            }}
          >
            previous
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className="slider-navigation-btn">
          <Typography className="asset-counter">
            {currentIndex + 1} / {content.length}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className="slider-navigation-btn">
          <div
            className="btn-carousel-navigation"
            style={{
              borderColor: props.sectionColor,
              opacity: currentIndex == content.length - 1 ? 0.2 : 1,
            }}
            onClick={() => {
              if (currentIndex == content.length - 1) return;
              let _idx = currentIndex + (1 % content.length);
              setCurrentIndex(_idx);
              if (props.onUpdate) {
                props.onUpdate(_idx);
              }

              // console.log("current content index ->", _idx);
            }}
          >
            next
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const MapContentDescription = (props) => {
  // {props.currentContent?.title || props.currentContent?.name}
  // {props.currentContent?.description}
  if (!props.currentContent) return null;
  // console.log("title =>", props.currentContent);

  let title = "";
  let description = "";
  switch (props.contentType) {
    case "accent-van":
      title = props.currentContent.title;
      description = props.currentContent.description.replace(/\-/g, "•");
      //props.currentContent.description;
      break;
    case "podcast":
      title = props.currentContent.title;
      description = props.currentContent.description;
      break;
    case "directions-task":
      title = props.currentContent.title;
      break;
    case "archive":
      title = props.currentContent.title;
      break;
    case "perception":
      title = props.currentContent.title;
      // if (props.isTheme) {
      description = props.currentContent.description;
      // }
      break;
    case "pronunciation":
      title = props.currentContent.title;
      // if (props.isTheme) {
      description = props.currentContent.description;
      // }
      break;
    case "poetry":
      title = props.currentContent.name;
      description = props.currentContent.author;

      break;
    default:
      break;
  }

  //console.log("props.currenContent -> ", props.currentContent);
  return (
    <>
      <Typography
        className="details-info-title"
        variant="h6"
        style={{
          color: props.isWhiteFont ? "white" : "",
        }}
      >
        {title}
      </Typography>
      <Typography
        className="details-info-description"
        style={{
          whiteSpace: "pre-line",
          color: props.isWhiteFont ? "white" : "",
        }}
      >
        {description}
      </Typography>
    </>
  );
};

const getTime = (time) => {
  return moment.utc(time * 1000).format("mm:ss");
};

// VIDEO
const ContentAsset = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [assetLength, setAssetLength] = useState(0);
  const [assetTotalLength, setAssetTotalLength] = useState("00:00");
  const [showMapTask, setShowMapTask] = useState(false);
  const playerRef = useRef();

  //console.log(props);

  const MediaControls = () => {
    return (
      <>
        <div
          className="asset-progress"
          // style={{ backgroundColor: props.sectionColor }}
        >
          <p>
            {getTime(assetLength)} / {assetTotalLength}
          </p>
        </div>
        <div
          className="current-asset-controls"
          style={{ backgroundColor: props.sectionColor }}
        >
          <div
            className="btn-repeat btn-control"
            onClick={() => {
              //console.log("....restart....");
              playerRef.current.seekTo(0);
            }}
          >
            <SkipPreviousIcon style={{ fontSize: 60 }} />
          </div>

          <div
            className="btn-play btn-control"
            onClick={() => {
              //console.log("....playing....");
              setIsPlaying((prev) => !prev);
            }}
          >
            {isPlaying ? (
              <PauseIcon style={{ fontSize: 60 }} />
            ) : (
              <PlayArrowIcon style={{ fontSize: 60 }} />
            )}
          </div>
        </div>
      </>
    );
  };

  switch (props.contentType) {
    case "accent-van":
      let videoPath = `/content/${props.asset.video}`;

      return (
        <div className="asset-player-holder">
          <ReactPlayer
            playsinline={true}
            url={videoPath}
            ref={playerRef}
            key={props.id}
            controls={true}
            playing={isPlaying}
            onReady={(player) => {
              setIsReady(true);
            }}
            className="video-player"
            onDuration={(num) => {
              setAssetTotalLength(getTime(num));
            }}
            onProgress={(p) => {
              setAssetLength(p.playedSeconds);
            }}
            height="100%"
            width="100%"
            onEnded={() => {
              setIsPlaying(false);
            }}
          />
          {!isPlaying && isReady && (
            <div
              className="big-play-btn"
              onClick={() => {
                setIsPlaying(true);
              }}
            >
              <PlayArrowIcon style={{ fontSize: "10vw" }} />
            </div>
          )}
          {/* <MediaControls /> */}
        </div>
      );
    case "directions-task":
      return (
        <div
          className="asset-player-holder"
          style={{ backgroundColor: showMapTask ? "white" : "" }}
        >
          <div
            className="direction-map-toggle"
            onClick={() => {
              setShowMapTask((prev) => !prev);
            }}
          >
            {showMapTask ? "hide task" : "show task"}
          </div>
          <ReactPlayer
            playsinline={true}
            url={`/content/${props.asset.audio}`}
            key={props.id}
            ref={playerRef}
            playing={isPlaying}
            onDuration={(num) => {
              setAssetTotalLength(getTime(num));
            }}
            onProgress={(p) => {
              setAssetLength(p.playedSeconds);
            }}
            // controls
            className="video-player"
            height="100%"
            width="100%"
            onEnded={() => {
              setIsPlaying(false);
            }}
          />
          {showMapTask ? (
            <div className="directions-task-map"></div>
          ) : (
            <div className="asset-text-holder">
              <Typography>
                {props.asset.description.replace(/\-/g, "•")}
                {/* <p
                  dangerouslySetInnerHTML={{
                    __html: props.asset.description.replace(/\-/g, "•"),
                  }}
                /> */}

                {/* {props.asset.description} */}
              </Typography>
            </div>
          )}

          <div
            className="audio-player"
            onClick={() => {
              setIsPlaying(true);
            }}
          ></div>
          <MediaControls />
        </div>
      );
    case "archive":
      return (
        <div className="asset-player-holder">
          <ReactPlayer
            playsinline={true}
            url={`/content/${props.asset.audio}`}
            key={props.id}
            ref={playerRef}
            playing={isPlaying}
            onDuration={(num) => {
              setAssetTotalLength(getTime(num));
            }}
            onProgress={(p) => {
              setAssetLength(p.playedSeconds);
            }}
            // controls
            className="video-player"
            height="100%"
            width="100%"
            onEnded={() => {
              setIsPlaying(false);
            }}
          />
          <div className="asset-text-holder">
            <Typography>{props.asset.description}</Typography>
          </div>
          <div
            className="audio-player"
            onClick={() => {
              setIsPlaying(true);
            }}
          ></div>
          <MediaControls />
        </div>
      );
    case "podcast":
      return (
        <div className="asset-player-holder">
          <ReactPlayer
            playsinline={true}
            url={`/content/${props.asset.audio}`}
            key={props.id}
            ref={playerRef}
            playing={isPlaying}
            onDuration={(num) => {
              setAssetTotalLength(getTime(num));
            }}
            onProgress={(p) => {
              setAssetLength(p.playedSeconds);
            }}
            // controls
            className="video-player"
            height="100%"
            width="100%"
            onEnded={() => {
              setIsPlaying(false);
            }}
          />
          <div className="asset-text-holder">
            <Typography>{props.asset.subtitle}</Typography>
          </div>
          <div
            className="audio-player"
            onClick={() => {
              setIsPlaying(true);
            }}
          ></div>
          <MediaControls />
        </div>
      );
    case "poetry":
      let hasAudio = props.asset.audio != "";
      return (
        <div className="asset-player-holder">
          <div className="asset-text-holder">
            <Typography>{props.asset.description}</Typography>
          </div>
          {hasAudio && (
            <>
              <ReactPlayer
                playsinline={true}
                url={`/content/${props.asset.audio}`}
                key={props.id}
                ref={playerRef}
                playing={isPlaying}
                onDuration={(num) => {
                  setAssetTotalLength(getTime(num));
                }}
                onProgress={(p) => {
                  setAssetLength(p.playedSeconds);
                }}
                // controls
                className="video-player"
                height="100%"
                width="100%"
                onEnded={() => {
                  setIsPlaying(false);
                }}
              />
              <div
                className="audio-player"
                onClick={() => {
                  setIsPlaying(true);
                }}
              ></div>
              <MediaControls />
            </>
          )}
        </div>
      );
    case "audio":
      return (
        <div className="asset-player-holder">
          <ReactPlayer
            playsinline={true}
            url={`/content/${props.asset.audio}`}
            key={props.id}
            ref={playerRef}
            playing={isPlaying}
            onDuration={(num) => {
              setAssetTotalLength(getTime(num));
            }}
            onProgress={(p) => {
              setAssetLength(p.playedSeconds);
            }}
            // controls
            className="video-player"
            height="100%"
            width="100%"
            onEnded={() => {
              setIsPlaying(false);
            }}
          />

          <div
            className="audio-player"
            onClick={() => {
              setIsPlaying(true);
            }}
          ></div>

          <MediaControls />
        </div>
      );
    case "perception":
      return (
        <div
          className="asset-player-holder"
          style={{
            whiteSpace: "pre-line",
            textAlign: "left",
            fontSize: 30,
            backgroundColor: "white",
          }}
        >
          {/* <div className="peception-ledger"> */}
          <div
            className="peception-ledger-holder"
            style={{ flex: 0.2, display: "flex" }}
          >
            <div className="map-ledger-text">most</div>
            <div className="map-ledger-perception map-ledger"></div>
            <div className="map-ledger-text">least</div>
          </div>
          {/* </div> */}

          <div
            className="map-image-holder"
            style={{ backgroundImage: `url(/content/${props.asset.images})` }}
          >
            {/* <img
              style={{ width: "100%" }}
              src={`/content/${props.asset.images}`}
              alt=""
            /> */}
          </div>

          {/* <h1>{props.asset.title}</h1> */}
          {/* <p>{props.asset.description}</p> */}
        </div>
      );

    case "pronunciation":
      let encode = encodeURIComponent(props.asset.main_image);
      return (
        <div
          className="asset-player-holder"
          style={{ backgroundColor: "white", display: "flex" }}
        >
          <div
            className="pronunciation-ledger-holder"
            style={{ flex: 0.2, display: "flex" }}
          >
            <div className="map-ledger-text">
              {props.asset.key_bottom_value}
            </div>
            <div className="map-ledger-pronunciation map-ledger"></div>
            <div className="map-ledger-text">{props.asset.key_top_value}</div>
          </div>
          <div
            className="audio-player"
            onClick={() => {
              setIsPlaying(true);
            }}
          ></div>
          {/* <div className="audio-description">{props.asset.title}</div> */}
          <ReactPlayer
            playsinline={true}
            url={`/content/${props.asset.audio}`}
            ref={playerRef}
            playing={isPlaying}
            key={props.id}
            onDuration={(num) => {
              setAssetTotalLength(getTime(num));
            }}
            onProgress={(p) => {
              setAssetLength(p.playedSeconds);
            }}
            // controls
            className="video-player"
            style={{ display: "none" }}
            height="100%"
            width="100%"
            onEnded={() => {
              setIsPlaying(false);
            }}
          />
          <div
            className="map-image-holder"
            style={{
              backgroundImage: `url("/content/${encode}")`,
            }}
          ></div>
          {/* <div
            className="audio-play-btn"
            onClick={() => {
              playerRef.current.seekTo(0);
              setIsPlaying(true);
            }}
            style={{ backgroundColor: props.sectionColor }}
          >
            <div className="play-audio-icon"></div>
            <div className="audio-btn-playing">play audio</div>
          </div> */}

          <MediaControls />
        </div>
      );
    default:
      break;
  }

  return <div>content type not found</div>;
};

export default MapContentType;

import "./Home.css";
import { Routes, Route, Link } from "react-router-dom";
import { Sections } from "../data/config-data";
import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography, Divider } from "@mui/material";

//assets
// import Landscape from "../assets/Manchester scape.svg";

const Home = () => {
  return (
    <AnimatePresence>
      <motion.div
        className="main-holder screen-page"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Grid container item lg={8} className="intro-text">
          <Typography variant="h5">Welcome to Manchester Voices!</Typography>
          <Typography m={2}>
            Manchester Voices is a research project run by a team from
            Manchester Metropolitan University, exploring the accents, dialects
            and identities of people from across the ten boroughs of Greater
            Manchester.
          </Typography>
          <Typography>
            The research took place between 2019 and 2022, collecting people’s
            voices, insights and attitudes in a celebration of language
            diversity. This interactive resource allows you to explore what we
            found. It contains videos, audio recordings, dialect maps,
            pronunciation maps, analysis, and much more, all organised by
            project area and by common themes.
          </Typography>
          <Typography>
            We will continue to add more video clips over the coming months.
          </Typography>
        </Grid>
        <Grid container>
          <div className="home-bg"></div>
          <Grid container className="navigation-holder">
            {Sections.map((v, i) => {
              return (
                <MainManuNavItem
                  key={i}
                  {...v}
                  delay={i}
                  // to={`/${v.path}`}
                  // name={v.name}
                  // color={v.color}
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid className="feedback-text">
          <p>
            When you have finished using the resource, we would be grateful if
            you could spare a few minutes to{" "}
            <a
              style={{ color: "white" }}
              href="https://forms.office.com/r/EGXvUnVeP7"
              target={"_blank"}
              rel={"noopener"}
            >
              give us some feedback.
            </a>
          </p>
        </Grid>
      </motion.div>
    </AnimatePresence>
  );
};

const MainManuNavItem = (props) => {
  let iconImage = "./assets/" + props.icon + ".png";
  let timer = Math.random() * 3;

  return (
    <Grid xs={6} sm={4} md={3} item className="nav-item-link">
      <Link to={props.path}>
        <div className="nav-item" style={{ backgroundColor: props.color }}>
          <motion.img
            animate={{ y: [0, 10, 0] }}
            transition={{
              duration: 2.5,
              delay: timer,
              repeat: Infinity,
            }}
            alt=""
            src={iconImage}
            className="nav-item-icon"
          />
          {props.name}
        </div>
      </Link>
    </Grid>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import "./Pronunciation.css";
import { Box, Grid, Typography } from "@mui/material";
import { Syllable, Themes, Sections } from "../data/config-data";
import MapContentType from "../components/MapContentType";
import ThemeSectionDetails from "../components/ThemeSectionDetails";

import {
  Accordion,
  AccordionSummary,
  ThemeButton,
} from "../components/CustomComponents";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import _ from "lodash";
import { GetContent } from "../Util";
import SeeMoreDetails from "../components/SeeMoreDetails";
import { motion, AnimatePresence } from "framer-motion";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const sectionColor = "#2BAD80";

const SECTION_INTRO_TEXT =
  "In order to find out how accents differ across the region, we analysed recordings of 130 people who have lived in one borough of Greater Manchester for most of their lives, all completing the same directions task. This involved describing a route on a map around a series of landmarks, chosen to represent words that we thought would differ in pronunciation across the region. We then analysed how they pronounced different vowels and consonants in these words. Finally, we created maps and audio examples showing how these pronunciations differ between the 10 boroughs of Greater Manchester.";
const Pronunciation = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});

  const [selectedCategoryKey, setSelectedCategoryKey] = useState("");
  useEffect(() => {
    let _content = GetContent("pronunciation");

    let groupd = _.groupBy(_content, "syllable");
    setData(groupd);
    //console.log("content -> ", groupd);
  }, []);

  const handleStep = (num, category) => {
    setSelectedCategoryKey(category);
    setStep(num);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="screen-page"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* <Grid container className="pronunciation-holder"> */}
        {step == 0 && <PronunciationIntro handleStep={handleStep} />}
        {step == 1 && (
          <PronunciationInfo
            handleStep={handleStep}
            categories={data}
            selectedCategoryKey={selectedCategoryKey}
            hideDetails={() => {
              setStep(0);
            }}
          />
        )}
        {/* </Grid> */}
      </motion.div>
    </AnimatePresence>
  );
};

const PronunciationIntro = (props) => {
  let navigate = useNavigate();

  return (
    <Grid container className="pronunciation-content-intro" spacing={2}>
      <Grid container item lg={5}>
        <div className="pronunciation-left">
          <div
            className="title-holder"
            style={{ backgroundColor: sectionColor }}
          >
            <motion.img
              animate={{ y: [0, 10, 0] }}
              transition={{
                duration: 3,
                // ease: "easeInOut",
                repeat: Infinity,
              }}
              alt=""
              src={"./assets/08 Pronounciation.png"}
              className="nav-item-icon"
              style={{ width: 70, height: 70, top: 0 }}
            />
            <div className="title-name">pronunciation</div>
          </div>

          <div
            className="pronunciation-description"
            style={{ backgroundColor: sectionColor }}
          >
            <Typography>{SECTION_INTRO_TEXT}</Typography>
          </div>
        </div>
      </Grid>
      <Grid container item lg={7}>
        <div className="pronunciation-right">
          <Typography variant="h5" className="pronunciation-category-title">
            Choose a category to explore our results.
          </Typography>
          <div className="pronunciation-category">
            {Object.keys(Syllable).map((v, i) => (
              <Grid
                item
                key={i}
                className="btn-pronunciation-category"
                style={{ backgroundColor: sectionColor }}
                onClick={() => {
                  props.handleStep(1, v);
                }}
              >
                {Syllable[v]}
              </Grid>
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const PronunciationInfo = (props) => {
  const [categories, SetCategories] = useState(props.categories);
  const [selectedCategoryKey, setSelectedCategoryKey] = useState(
    props.selectedCategoryKey
  );
  const [selectedCategoryData, setSelectedCategoryData] = useState(
    categories[selectedCategoryKey]
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexData, setcurrentIndexData] = useState(
    selectedCategoryData[currentIndex]
  );
  const [openSeeMore, setOpenSeeMore] = useState(false);
  const [seeMoreContent, setSeeMoreContent] = useState([]);
  const [contentThemes, setContentThemes] = useState([]);
  const [openThemeDetails, setOpenThemeDetails] = useState(false);
  const [currentTheme, setCurrentTheme] = useState("");
  const [settings, setSettings] = useState({});
  let navigate = useNavigate();

  // let settings = {
  //   title: "Archive",
  //   icon: "05 Archive",
  //   section: "pronunciation",
  //   contentType: "pronunciation",
  //   mapImage: "Map_Archive",
  //   color: "#27838E",
  // };

  useEffect(() => {
    CheckSeeAlsoContent(currentIndexData);
    let ct = Sections.filter((obj) => {
      return obj.path === "pronunciation";
    })[0];

    setSettings(ct);
  }, []);

  const UpdateThemes = (cont) => {
    let cat = cont.categories.toString();
    let _themes = [];
    // console.log("cat  ", cat);
    if (cat.indexOf(",") > -1) {
      let split = cat.split(",");
      _themes = [...split];
    } else {
      _themes.push(cat);
    }
    setContentThemes(_themes);
  };

  const CheckSeeAlsoContent = (cont) => {
    //console.log("checking see more .....", cont);
    UpdateThemes(cont);

    if (cont.seealso) {
      let cat = cont.seealso.toString();
      let _sa = [];
      if (cat) {
        if (cat.indexOf(",") > -1) {
          let split = cat.split(",");
          _sa = [...split];
        } else {
          _sa.push(cat);
        }
        setSeeMoreContent(_sa);
      }
    } else {
      setSeeMoreContent([]);
    }
  };

  return (
    <>
      {openThemeDetails && (
        <ThemeSectionDetails
          settings={settings}
          sectionColor={sectionColor}
          theme={currentTheme}
          currentSection={"pronunciation"}
          handleThemeDetails={() => {
            setOpenThemeDetails(false);
          }}
        />
      )}
      <Grid
        container
        className="section-content-info"
        style={{ display: openThemeDetails || openSeeMore ? "none" : "flex" }}
      >
        <Grid item container lg={5}>
          <div className="pronunciation-left">
            <div
              className="title-holder"
              style={{ backgroundColor: sectionColor }}
            >
              <motion.img
                animate={{ y: [0, 10, 0] }}
                transition={{
                  duration: 3,
                  // ease: "easeInOut",
                  repeat: Infinity,
                }}
                alt=""
                src={"./assets/08 Pronounciation.png"}
                className="nav-item-icon"
                style={{ width: 70, height: 70, top: 0 }}
              />
              <div className="title-name">pronunciation</div>
            </div>
            <div
              className="pronunciation-category-name"
              style={{ color: sectionColor }}
            >
              {Syllable[selectedCategoryKey]}
            </div>
            {/* <div className="pronunciation-category-description">
              <Typography>
                {SECTION_INTRO_TEXT}

              </Typography>
            </div> */}
            <Accordion>
              <AccordionSummary>
                <h4>Related themes</h4>
              </AccordionSummary>

              <div className="themes-holder">
                {Object.keys(Themes).map((v, i) => (
                  <ThemeButton
                    key={v}
                    disable={!contentThemes.includes(v)}
                    name={Themes[v].name}
                    handleThemeDetails={() => {
                      setOpenThemeDetails(true);
                      setCurrentTheme(v);
                    }}
                  />
                ))}
              </div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h4>Explore Categories</h4>
              </AccordionSummary>

              <Grid container className="pronunciation-navigation-holder">
                {Object.keys(Syllable).map((v, i) => {
                  let isSelected = v == selectedCategoryKey;
                  return (
                    <Grid
                      item
                      xs={5}
                      sm={4}
                      key={i}
                      className="btn-pronunciation-category"
                      style={{
                        backgroundColor: isSelected ? "#461083" : sectionColor,
                      }}
                      onClick={() => {
                        // props.handleStep(1, v);
                        setSelectedCategoryKey(v);
                        setSelectedCategoryData(categories[v]);
                        CheckSeeAlsoContent(categories[v][0]);

                        setCurrentIndex(0);
                      }}
                    >
                      {Syllable[v]}
                    </Grid>
                  );
                })}
              </Grid>
            </Accordion>
            {/* <div className="btn-navigation-holder">
          <div
            className="btn-details-home btn-nav"
            onClick={() => {
              navigate("/");
            }}
          >
            <span>home</span>
          </div>
          <div
            className="btn-details-back btn-nav"
            onClick={() => {
              props.handleStep(0);
            }}
          >
            <span>back</span>
          </div>
        </div> */}
          </div>
        </Grid>
        <Grid item container lg={7} justifyContent="center">
          <div className="pronunciation-right">
            <div className="pronunciation-map-holder">
              <MapContentType
                contentType={"pronunciation"}
                content={selectedCategoryData}
                key={selectedCategoryData.length}
                sectionColor={sectionColor}
                onUpdate={(idx) => {
                  setCurrentIndex(idx);
                  CheckSeeAlsoContent(selectedCategoryData[idx]);
                  //UpdateThemes(selectedCategoryData[idx]);

                  // setCurrentSliderIndex(idx);
                  // UpdateThemes(currentContent[idx]);
                  // console.log("updating index", idx);
                }}
              />

              {seeMoreContent.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="btn-seemore"
                    // style={{ right: 500 }}
                    onClick={() => {
                      setOpenSeeMore(true);
                    }}
                  >
                    <KeyboardArrowUpIcon style={{ fontSize: 60 }} /> See also
                  </div>
                </div>
              )}
            </div>
          </div>
        </Grid>
        <div className="home-back-navigation">
          <div
            className="btn-details-home btn-nav"
            onClick={() => {
              navigate("/");
            }}
          >
            <span>home</span>
          </div>
          <div className="btn-details-back btn-nav" onClick={props.hideDetails}>
            <span>back</span>
          </div>
        </div>
      </Grid>
      <AnimatePresence>
        {openSeeMore && (
          <SeeMoreDetails
            contentKeys={seeMoreContent}
            // key={`${selectedPostcode}_${currentSection}`}
            handleSeeMoreState={() => {
              setOpenSeeMore(false);
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Pronunciation;

// import { Content } from "./data/config-data";
import Content from "./content/content.json";

const GetContent = (section) => {
  let data = Content.sections[section];
  return data;
};
const GetAllContent = () => {
  return Content.sections;
};
export { GetContent, GetAllContent };

import React, { useState, useEffect } from "react";
import "./Perception.css";
import { Box, Grid, Typography } from "@mui/material";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { PerceptionCategories, Sections, Themes } from "../data/config-data";
import MapContentType from "../components/MapContentType";
import { Carousel } from "react-responsive-carousel";
import { GetContent } from "../Util";
import _ from "lodash";
import SeeMoreDetails from "../components/SeeMoreDetails";
import { motion, AnimatePresence } from "framer-motion";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Accordion,
  AccordionSummary,
  ThemeButton,
} from "../components/CustomComponents";
import ThemeSectionDetails from "../components/ThemeSectionDetails";

const sectionColor = "#2BAD80";

const Perception = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const [selectedCategoryKey, setSelectedCategoryKey] = useState("");

  useEffect(() => {
    let _content = GetContent("perception");
    let groupd = _.groupBy(_content, "perception");
    setData(groupd);
  }, []);

  const handleStep = (num, category) => {
    setSelectedCategoryKey(category);
    setStep(num);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="screen-page"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {step == 0 && <PerceptionIntro handleStep={handleStep} />}
        {step == 1 && (
          <PerceptionInfo
            handleStep={handleStep}
            categories={data}
            hideDetails={() => {
              setStep(0);
            }}
            selectedCategoryKey={selectedCategoryKey}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const PerceptionIntro = (props) => {
  let navigate = useNavigate();

  return (
    <Grid container className="perception-content-intro" spacing={2}>
      <Grid container item lg={5}>
        <div className="perception-left">
          <div
            className="title-holder"
            style={{ backgroundColor: sectionColor }}
          >
            <motion.img
              animate={{ y: [0, -10, 0] }}
              transition={{
                duration: 3,
                // ease: "easeInOut",
                repeat: Infinity,
              }}
              alt=""
              src={"./assets/07 Perception.png"}
              className="nav-item-icon"
              style={{ width: 70, height: 70, top: 0 }}
            />
            <div className="title-name">Perception</div>
          </div>

          <div
            className="perception-description"
            style={{ backgroundColor: sectionColor }}
          >
            <Typography>
              We gave 349 people who live in Greater Manchester a map of the
              region. We asked them to draw shapes around areas where they
              thought certain accents or dialects were spoken, before describing
              and labelling them. They gave the area a name, described what they
              thought of the accent or dialect, and gave examples of its
              pronunciations, words or grammar. We combined all these responses
              together to create composite maps of the different dialect regions
              and themes that emerged from the data.
            </Typography>
          </div>
          {/* <div
          className="btn-overview-home"
          onClick={() => {
            navigate("/");
          }}
        >
          <span>home</span>
        </div> */}
        </div>
      </Grid>
      <Grid item container lg={7}>
        <div className="perception-right">
          <Typography variant="h5">
            Choose a category to explore our results
          </Typography>
          <Grid container className="perception-category">
            {Object.keys(PerceptionCategories).map((v, i) => (
              <Grid
                item
                key={i}
                xs={12}
                sm={3}
                md={3}
                className="btn-perception-category"
                style={{ backgroundColor: PerceptionCategories[v].color }}
                onClick={() => {
                  //console.log(v);
                  // return;
                  props.handleStep(1, v);
                }}
              >
                {PerceptionCategories[v].name}
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const PerceptionInfo = (props) => {
  const [categories, SetCategories] = useState(props.categories);
  const [selectedCategoryKey, setSelectedCategoryKey] = useState(
    props.selectedCategoryKey
  );
  const [selectedCategoryData, setSelectedCategoryData] = useState(
    categories[selectedCategoryKey]
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexData, setcurrentIndexData] = useState(
    selectedCategoryData[currentIndex]
  );
  const [openSeeMore, setOpenSeeMore] = useState(false);
  const [seeMoreContent, setSeeMoreContent] = useState([]);
  const [contentThemes, setContentThemes] = useState([]);
  const [openThemeDetails, setOpenThemeDetails] = useState(false);
  const [currentTheme, setCurrentTheme] = useState("");
  const [settings, setSettings] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    CheckSeeAlsoContent(currentIndexData);

    let ct = Sections.filter((obj) => {
      return obj.path === "perception";
    })[0];

    console.log("--->", ct);
    setSettings(ct);
  }, []);

  const UpdateThemes = (cont) => {
    let cat = cont.categories.toString();
    let _themes = [];
    // console.log("cat  ", cat);
    if (cat.indexOf(",") > -1) {
      let split = cat.split(",");
      _themes = [...split];
    } else {
      _themes.push(cat);
    }
    setContentThemes(_themes);
  };

  const CheckSeeAlsoContent = (cont) => {
    //console.log("checking see more .....", cont);
    UpdateThemes(cont);

    if (cont.seealso) {
      let cat = cont.seealso.toString();
      let _sa = [];
      if (cat) {
        if (cat.indexOf(",") > -1) {
          let split = cat.split(",");
          _sa = [...split];
        } else {
          _sa.push(cat);
        }
        setSeeMoreContent(_sa);
      }
    } else {
      setSeeMoreContent([]);
    }
  };

  return (
    <>
      {openThemeDetails && (
        <ThemeSectionDetails
          settings={settings}
          sectionColor={sectionColor}
          theme={currentTheme}
          currentSection={"perception"}
          handleThemeDetails={() => {
            setOpenThemeDetails(false);
          }}
        />
      )}
      <Grid
        container
        className="section-content-info"
        style={{ display: openThemeDetails || openSeeMore ? "none" : "flex" }}
      >
        <Grid item container lg={5}>
          <div className="perception-left">
            <div
              className="title-holder"
              style={{ backgroundColor: sectionColor }}
            >
              <motion.img
                animate={{ y: [0, 10, 0] }}
                transition={{
                  duration: 3,
                  // ease: "easeInOut",
                  repeat: Infinity,
                }}
                alt=""
                src={"./assets/07 Perception.png"}
                className="nav-item-icon"
                style={{ width: 70, height: 70, top: 0 }}
              />
              <div className="title-name">perception</div>
            </div>
            <div
              className="perception-category-name"
              style={{ color: sectionColor }}
            >
              {PerceptionCategories[selectedCategoryKey].name}
            </div>
            {/* <div className="perception-category-description">
              <Typography>
                {selectedCategoryData[currentIndex]?.description}
              </Typography>
            </div> */}
            <Accordion>
              <AccordionSummary>
                <h4>Related themes</h4>
              </AccordionSummary>
              <div className="themes-holder">
                {Object.keys(Themes).map((v, i) => (
                  <ThemeButton
                    key={v}
                    disable={!contentThemes.includes(v)}
                    name={Themes[v].name}
                    handleThemeDetails={() => {
                      setOpenThemeDetails(true);
                      setCurrentTheme(v);
                    }}
                  />
                ))}
              </div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h4>Explore Categories</h4>
              </AccordionSummary>
              <Grid container className="perception-navigation-holder">
                {Object.keys(PerceptionCategories).map((v, i) => {
                  let isSelected = selectedCategoryKey == v;
                  // console.log(isSelected);
                  return (
                    <Grid
                      item
                      xs={5.5}
                      sm={4}
                      key={i}
                      className="perception-nav-btn"
                      style={{
                        backgroundColor: isSelected ? "#461083" : sectionColor,
                        boxShadow: isSelected
                          ? "0px -8px 0px 0px rgba(0, 0, 0, 0.3)"
                          : "0px 5px 0px 0px rgba(0, 0, 0, 0.3)",
                      }}
                      onClick={() => {
                        // return;
                        setSelectedCategoryKey(v);
                        setSelectedCategoryData(categories[v]);
                        setCurrentIndex(0);
                        CheckSeeAlsoContent(categories[v][0]);
                        props.handleStep(1, v);
                      }}
                    >
                      {PerceptionCategories[v].name}
                    </Grid>
                  );
                })}
              </Grid>
            </Accordion>
            {/* 
          <div className="btn-navigation-holder">
            <div
              className="btn-details-home btn-nav"
              onClick={() => {
                navigate("/");
              }}
            >
              <span>home</span>
            </div>
            <div
              className="btn-details-back btn-nav"
              onClick={() => {
                props.handleStep(0);
              }}
            >
              <span>back</span>
            </div>
          </div> */}
          </div>
        </Grid>
        <Grid item container lg={7}>
          <div className="perception-right">
            <div className="perception-map-holder">
              <MapContentType
                contentType={"perception"}
                content={selectedCategoryData}
                key={selectedCategoryData.length}
                sectionColor={sectionColor}
                onUpdate={(idx) => {
                  setCurrentIndex(idx);
                  CheckSeeAlsoContent(selectedCategoryData[idx]);

                  // setCurrentSliderIndex(idx);
                  // UpdateThemes(currentContent[idx]);
                  // console.log("updating index", idx);
                }}
              />
              {seeMoreContent.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="btn-seemore"
                    onClick={() => {
                      setOpenSeeMore(true);
                    }}
                  >
                    <KeyboardArrowUpIcon style={{ fontSize: 60 }} /> See also
                  </div>
                </div>
              )}
            </div>
          </div>
        </Grid>
        <div className="home-back-navigation">
          <div
            className="btn-details-home btn-nav"
            onClick={() => {
              navigate("/");
            }}
          >
            <span>home</span>
          </div>
          <div className="btn-details-back btn-nav" onClick={props.hideDetails}>
            <span>back</span>
          </div>
        </div>
      </Grid>

      <AnimatePresence>
        {openSeeMore && (
          <SeeMoreDetails
            contentKeys={seeMoreContent}
            // key={`${selectedPostcode}_${currentSection}`}
            handleSeeMoreState={() => {
              setOpenSeeMore(false);
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Perception;

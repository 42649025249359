import React from "react";
import MapSectionMenu from "../components/MapSectionMenu";

const AccentVan = () => {
  const settings = {
    title: "Accent Van",
    icon: "04 Accent van",
    section: "accent-van",
    contentType: "accent-van",
    mapImage: "Map_AccentVan",
    color: "#C12AA6",
  };

  return (
    <>
      <MapSectionMenu {...settings} />
    </>
  );
};

export default AccentVan;
